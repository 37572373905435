import React, { useCallback, useState } from 'react';
import Tenant from '../types/tenant';
import { IReservation } from '../features/Reservations';
import { IUser } from '../types/interfaces';
import { TenantContext, TenantContextProviderProps } from '.';
import { useAgentService } from '../services';

/**
 * TenantContextProvider
 *
 * @param {*} { children }
 * @return {*}
 */
const TenantContextProvider: React.FC<TenantContextProviderProps> = ({ children }) => {
  const agentService = useAgentService();

  const [tenant, setTenant] = useState<Tenant | undefined>(undefined);
  const [users, setUsers] = useState<IUser[]>([]);
  const [reservations, setReservations] = useState<IReservation[]>([]);

  const fetchTenant = useCallback(async () => {
    const tenantResponse = await agentService.fetchTenant();
    console.log('tenantResponse', tenantResponse);
    if (tenantResponse) {
      setTenant(tenantResponse);
    }
    return tenantResponse;
  }, [agentService]);

  const fetchUsers = useCallback(async () => {
    const usersResponse = await agentService.fetchUsers();
    console.log('usersResponse', usersResponse);

    if (usersResponse) {
      setUsers(usersResponse);
    }

    return usersResponse;
  }, [agentService]);

  const fetchReservation = useCallback(
    async (email: string, reservationId: string) => {
      const reservation = await agentService.fetchReservation(email, reservationId);
      console.log('reservation', reservation);

      return reservation;
    },
    [agentService]
  );

  const fetchReservations = useCallback(async () => {
    const reservationsResponse = await agentService.fetchReservations();
    console.log('reservationsResponse', reservationsResponse);
    setReservations(reservationsResponse);

    return reservationsResponse;
  }, [agentService]);

  return (
    <TenantContext.Provider
      value={{
        tenant,
        reservations,
        users,
        fetchTenant,
        fetchUsers,
        fetchReservation,
        fetchReservations
      }}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantContextProvider;

import React, { useState } from 'react';
import { View, Button, ScrollView, StyleSheet } from 'react-native';
import UserData from './components/UserData';
import BlankModal from '../modal/BlankModal';
import RegisterUserForm from './components/RegisterUserForm';
import TenantData from './components/TenantData';
import { useTenantContext } from '../contexts';
import { INewUser } from '../types/user';
import { useAgentService } from '../services';

const Navigator: React.FC = () => {
  const agentService = useAgentService();
  const tenantContext = useTenantContext();

  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const isCreateInviteDisabled = !tenantContext.tenant;

  const createInvitation = async (data: INewUser) => {
    await agentService.fetchInvitation(data);
    await tenantContext.fetchReservations();
    setInviteModalOpen(false);
  };

  return (
    <ScrollView style={styles.container}>
      <View style={styles.timelineItem}>
        <BlankModal
          title="Register New User"
          open={inviteModalOpen}
          onClose={() => setInviteModalOpen(false)}>
          <RegisterUserForm handleRegistration={createInvitation} />
        </BlankModal>
        <Button
          title="Create invite"
          disabled={isCreateInviteDisabled}
          onPress={() => setInviteModalOpen(true)}
        />
      </View>

      <UserData />
      <TenantData />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16
  },
  timelineItem: {
    marginBottom: 16
  },
  textArea: {
    borderColor: '#ccc',
    borderWidth: 1,
    padding: 8,
    marginVertical: 8,
    height: 100
  },
  picker: {
    height: 50,
    width: '100%',
    marginVertical: 8
  },
  closeIcon: {
    fontSize: 24,
    color: 'red',
    textAlign: 'right'
  }
});

export default Navigator;

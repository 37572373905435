import { mdiCloseThick } from '@mdi/js';
import Icon from '@mdi/react';
import { PropsWithChildren } from 'react';
import { Modal, Pressable, StyleSheet, Text, View } from 'react-native';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 6,
    borderRadius: 10,
    width: 500,
    alignItems: 'center'
  },

  modalCloseIcon: {
    width: 24,
    height: 24,
    color: '#333'
  },
  textTitle: {
    fontSize: 24,
    fontWeight: 'bold'
  }
});

export interface ModalActions {
  title: string;
  open: boolean;
  onClose: () => void;
}

const BlankModal: React.FC<PropsWithChildren<ModalActions>> = ({
  title,
  open,
  onClose,
  children
}) => {
  return (
    <>
      <Modal onRequestClose={() => onClose()} visible={open} transparent={true}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <View style={styles.modalHeader}>
              <Text style={styles.textTitle}>{title}</Text>
              <Pressable
                onPress={() => {
                  localStorage.clear();
                  onClose();
                }}>
                <Icon path={mdiCloseThick} style={styles.modalCloseIcon} />
              </Pressable>
            </View>
            {children}
          </View>
        </View>
      </Modal>
    </>
  );
};

export default BlankModal;

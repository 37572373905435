import './App.css';
import AppRouter from './routes/AppRouter';
import Layout from './components/layout/Layout';
import React from 'react';

function App(): React.ReactElement {
  return (
    <Layout title="Quartech Demo Issuer Application">
      <AppRouter />
    </Layout>
  );
}

export default App;

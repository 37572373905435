import React from 'react';
import { Text, View } from 'react-native';
import { useTenantContext } from '../../contexts';

const TenantData: React.FC = () => {
  const tenantContext = useTenantContext();

  return (
    <View style={{ paddingTop: 16 }}>
      <Text style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>Tenant Information</Text>
      {!tenantContext.tenant ? (
        <Text style={{ fontWeight: 'bold', color: 'white' }}>
          Please log in to view tenant data.
        </Text>
      ) : (
        <>
          <Text style={{ fontWeight: 'bold', color: 'white' }}>
            Tenant WalletId: {tenantContext.tenant?.walletId}
          </Text>
          <Text style={{ fontWeight: 'bold', color: 'white' }}>
            Tenant Token: {tenantContext.tenant?.token}
          </Text>
        </>
      )}
    </View>
  );
};

export default TenantData;

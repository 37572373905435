import React from 'react';
import { DimensionValue, Pressable, StyleSheet, Text, TextStyle, ViewStyle } from 'react-native';

type GenericButtonProps = {
  title: string;
  onPress: () => void;
  isActive: boolean;
  buttonStyle?: ViewStyle;
  textStyle?: TextStyle;
};

const GenericButton: React.FC<GenericButtonProps> = (props) => {
  const styles = StyleSheet.create({
    genericButtonActive: {
      textAlign: 'center',
      backgroundColor: 'black',
      marginTop: 20,
      borderRadius: 0,
      padding: 5,
      height: 'fit-content' as DimensionValue
    },
    genericButtonText: {
      textAlign: 'center',
      color: 'white',
      fontSize: 16
    },
    genericButtonInactive: {
      textAlign: 'center',
      backgroundColor: 'gray',
      marginTop: 20,
      borderRadius: 0,
      opacity: 0.6,
      padding: 5,
      height: 'fit-content' as DimensionValue
    }
  });
  return (
    <Pressable
      style={[
        props.isActive ? styles.genericButtonActive : styles.genericButtonInactive,
        props.buttonStyle
      ]}
      disabled={!props.isActive}
      onPress={props.onPress}>
      <Text style={[styles.genericButtonText, props.textStyle]}>{props.title}</Text>
    </Pressable>
  );
};

export default GenericButton;

import React, { useEffect, useRef } from 'react';
import { Animated, DimensionValue, StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  loader: {
    width: 48,
    height: 48,
    borderWidth: 5,
    borderColor: 'white',
    borderBottomColor: 'transparent',
    borderRadius: 24,
    borderStyle: 'solid'
  },
  container: {
    flex: 1,
    height: '100vh' as DimensionValue,
    width: '100vw' as DimensionValue,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export interface ISpinnerProps {
  styleOverride?: any;
}

/**
 * Spinner component
 *
 * @return {*}
 */
const Spinner: React.FC<ISpinnerProps> = ({ styleOverride }) => {
  const rotateAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    // Define and start the animation loop
    Animated.loop(
      Animated.timing(rotateAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: false
      })
    ).start();
  }, [rotateAnim]);

  // Interpolate rotation values
  const rotate = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  });

  const style = { ...styles.loader, ...styleOverride };

  return <Animated.View style={[style, { transform: [{ rotate }] }]} />;
};

export const FullScreenSpinner: React.FC = () => {
  return (
    <View style={styles.container}>
      <Spinner />
    </View>
  );
};

export default Spinner;

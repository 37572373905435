import React from 'react';
import { View, StyleSheet, Button } from 'react-native';
import { useAuth } from 'react-oidc-context';

const HomePage: React.FC = () => {
  const auth = useAuth();

  return (
    <View style={styles.container}>
      <h1>Welcome to Quartech Demo Issuer Application!</h1>
      {auth.user?.profile.name && <h2>User: {auth.user?.profile.name}</h2>}
      <Button
        title="Looking for Wallet? Click here"
        onPress={() => window.open('http://localhost:5173/', '_blank')} //to open new page
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold'
  }
});

export default HomePage;

import React from 'react';
import { useAuth } from 'react-oidc-context';
import { PathRouteProps } from 'react-router-dom';
import { FullScreenSpinner } from '../components/molecules/Spinner';
import { jwtDecode, JwtPayload } from 'jwt-decode';

interface AdminAuthGuardProps extends PathRouteProps {
  fallback: JSX.Element;
}

interface IKeycloakRoles extends JwtPayload {
  resource_access: { 'trust-services-issuer': { roles: string[] } };
}

const AdminAuthGuard: React.FC<AdminAuthGuardProps> = ({ children, fallback }) => {
  const auth = useAuth();

  if (!children) return null;

  if (auth.isLoading) {
    return <FullScreenSpinner />;
  }

  if (!auth.isAuthenticated) {
    return fallback;
  }

  const accessToken = auth.user?.access_token;
  if (accessToken) {
    const decoded = jwtDecode(accessToken);
    const trustServicesIssuer = decoded as IKeycloakRoles;

    if (
      !trustServicesIssuer ||
      !trustServicesIssuer.resource_access ||
      !trustServicesIssuer.resource_access['trust-services-issuer'] ||
      !trustServicesIssuer.resource_access['trust-services-issuer'].roles
    ) {
      return fallback;
    }

    const roles = trustServicesIssuer.resource_access['trust-services-issuer']?.roles;
    if (roles === undefined || !roles || !roles?.includes('admin')) {
      return fallback;
    }
  }

  return children;
};

export default AdminAuthGuard;

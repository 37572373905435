export interface ITenant {
  tenant_name: string;
  token: string;
  wallet_id: string;
  tokenExpiry?: number | null;
}

/**
 * Tenant class
 *
 * @export
 * @class Tenant
 */
export default class Tenant {
  private tenant_name: string;
  private wallet_id: string;

  token: string;
  tokenExpiry: number | null = null;

  constructor(data: ITenant) {
    this.tenant_name = data.tenant_name || 'tenant';
    this.wallet_id = data.wallet_id;

    this.token = data.token;
    this.tokenExpiry = data.tokenExpiry || null;
  }

  get tenantName(): string {
    return this.tenant_name;
  }

  get walletId(): string {
    return this.wallet_id;
  }
}

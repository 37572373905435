import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminAuthGuard from '../guards/AdminAuthGuard';
import TenantPage from '../pages/TenantPage';
import AuthGuard from '../guards/AuthGuard';
import FallbackPage from '../pages/FallbackPage';
import OnboardPage from '../pages/OnboardPage';
import HomePage from '../pages/HomePage';
import TenantContextProvider from '../contexts/TenantContext';

const AppRouter = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AdminAuthGuard fallback={<HomePage />}>
              <TenantContextProvider>
                <TenantPage />
              </TenantContextProvider>
            </AdminAuthGuard>
          }
        />
        <Route
          path={'/user/invite/:id'}
          element={
            <AuthGuard fallback={<FallbackPage />}>
              <TenantContextProvider>
                <OnboardPage />
              </TenantContextProvider>
            </AuthGuard>
          }
        />
        <Route path="*" element={<FallbackPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;

import React from 'react';
import { Text, View } from 'react-native';
import { useAuth } from 'react-oidc-context';

const UserData: React.FC = () => {
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    return <Text>Please log in to view user data.</Text>;
  }

  return (
    <View>
      <Text style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>User Information</Text>
      <Text style={{ fontWeight: 'bold', color: 'white' }}>
        Username: {auth.user?.profile?.preferred_username}
      </Text>
      <Text style={{ fontWeight: 'bold', color: 'white' }}>Email: {auth.user?.profile?.email}</Text>
      <Text style={{ fontWeight: 'bold', color: 'white' }}>
        Full Name: {auth.user?.profile?.name}
      </Text>
    </View>
  );
};

export default UserData;

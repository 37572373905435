import React from 'react';
import { StyleSheet, Text, View, Modal } from 'react-native';
import GenericButton from '../components/molecules/GenericButton';
import { useAuthStateContext } from '../contexts';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    width: 300,
    alignItems: 'center'
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '50%'
  }
});

interface LogoutModal {
  modalVisible: boolean;
  setModalVisible: (modalVisible: boolean) => void;
}

const LogoutModal: React.FC<LogoutModal> = ({ modalVisible, setModalVisible }) => {
  const authStateContext = useAuthStateContext();
  const confirmLogout = async () => {
    authStateContext.auth.signoutRedirect();
    setModalVisible(false);
  };

  return (
    <Modal visible={modalVisible} transparent={true} onRequestClose={() => setModalVisible(false)}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text>Are you sure you want to log out?</Text>
          <View style={styles.buttonContainer}>
            <GenericButton title="YES" isActive={true} onPress={confirmLogout} />
            <GenericButton
              title="NO"
              isActive={true}
              onPress={() => {
                setModalVisible(false);
              }}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default LogoutModal;

import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { StyleSheet, Text, View } from 'react-native';

interface INewUser {
  email: string;
  name: string;
}

export interface IRegisterUserFormProps {
  handleRegistration: (data: INewUser) => void;
}

const RegisterUserForm: React.FC<IRegisterUserFormProps> = ({ handleRegistration }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<INewUser>({
    defaultValues: {
      email: '',
      name: ''
    }
  });

  const onSubmit: SubmitHandler<INewUser> = (data) => {
    console.log(data);
    handleRegistration(data);
    // Add your form submission logic here
  };

  return (
    <View style={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <View style={styles.formContainer}>
          <Text style={styles.textLabel}>Email: </Text>
          <input style={styles.formTextArea} {...register('email', { required: true })} />
          {errors.email && <Text style={styles.errorText}>This field is required</Text>}
          <Text style={styles.textLabel}>Name: </Text>
          <input style={styles.formTextArea} {...register('name', { required: true })} />
          {errors.name && <Text style={styles.errorText}>This field is required</Text>}

          <div style={{ marginTop: 12 }} />
          <button type="submit">Submit</button>
        </View>
      </form>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 4,
    width: '100%'
  },
  formContainer: {
    padding: 20,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: '#333',
    borderRadius: 10,
    backgroundColor: '#f0f0f0'
  },
  textLabel: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  formTextArea: {
    borderWidth: 1,
    borderColor: '#333',
    padding: 10,
    marginVertical: 10,
    color: 'black',
    backgroundColor: 'white'
  },
  formCloseIcon: {
    fontSize: 20,
    textAlign: 'center'
  },
  errorText: {
    color: 'red'
  }
});

export default RegisterUserForm;

import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Button } from 'react-native';
import { IReservation } from '../features/Reservations';
import Spinner from '../components/molecules/Spinner';
import { useAuthStateContext, useTenantContext } from '../contexts';
import { useAgentService } from '../services';

const OnboardPage: React.FC = () => {
  const authStateContext = useAuthStateContext();
  const tenantContext = useTenantContext();
  const agentService = useAgentService();

  const [reservation, setReservation] = useState<IReservation | undefined>(undefined);
  const [wallet, setWallet] = useState<any | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(false);

  const email = String(authStateContext.auth.user?.profile.email);
  const reservationId = String(location.pathname.split('/').pop());

  useEffect(() => {
    const fetchData = async () => {
      const reservation = await tenantContext.fetchReservation(email, reservationId);
      setReservation(reservation);
    };

    if (!tenantContext.tenant || !tenantContext.reservations) {
      fetchData();
    }
  }, [email, reservationId, tenantContext]);

  const handleCreateWallet = async () => {
    setLoading(true);
    const walletResponse = await agentService.createWallet();

    if (walletResponse) {
      setWallet(walletResponse);
      setLoading(false);
    }
  };

  if (wallet) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ marginBottom: 16, padding: 8 }}>
          <div>
            <Text style={styles.title}>Wallet Created Successfully</Text>
            <div />

            <Button
              title="Looking for Wallet? Click here"
              onPress={() => window.open('http://localhost:5173/', '_blank')} //to open new page
            />
          </div>
        </div>
      </View>
    );
  }

  if (!reservation) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ marginBottom: 16, padding: 8 }}>
          <div>
            <Text style={styles.title}>No Reservation Found</Text>
            <div />

            <Button
              title="Looking for Wallet? Click here"
              onPress={() => window.open('http://localhost:5173/', '_blank')} //to open new page
            />
          </div>
        </div>
      </View>
    );
  }

  if (loading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ ...styles.title, color: 'white' }}>Creating Wallet...</Text>
        <Spinner />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <div style={{ marginBottom: 16, padding: 8 }}>
        <div>
          <Text style={styles.title}>Onboarding User</Text>
          <div />
          <Text style={styles.details}>
            <strong>EMAIL:</strong> {reservation.email}
          </Text>
          <Text style={styles.details}>
            <strong>NAME:</strong> {reservation.tenant_name}
          </Text>
          <Text style={styles.details}>
            <strong>RESERVATION ID:</strong> {reservation.reservation_id}
          </Text>
          <Button title="Create Wallet" onPress={handleCreateWallet} />
        </div>
      </div>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 16
  },
  title: {
    fontSize: 24,
    textAlign: 'center'
  },
  details: {
    fontSize: 16,
    marginBottom: 16
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 16,
    paddingHorizontal: 8
  }
});

export default OnboardPage;

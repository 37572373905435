import { PropsWithChildren } from 'react';
import { StyleSheet, Text, View } from 'react-native';

const styles = StyleSheet.create({
  footerStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    backgroundColor: '#EEE',
    bottom: 0
  }
});

const Footer: React.FC<PropsWithChildren> = () => {
  return (
    <View style={styles.footerStyle}>
      <Text>Footer</Text>
    </View>
  );
};

export default Footer;

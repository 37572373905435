import Tenant from '../types/tenant';
import { IReservation } from '../features/Reservations';
import { IUser } from '../types/interfaces';
import { INewUser } from '../types/user';

/**
 * AgentService class
 *
 * @export
 * @class AgentService
 */
export default class AgentService {
  constructor(private token: string) {
    this.token = token;
  }
  /**
   * Fetch tenant
   *
   * @return {*}  {Promise<Tenant>}
   * @memberof AgentService
   */
  async fetchTenant(): Promise<Tenant> {
    const res = await fetch('/api/admin/tenant', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    });

    if (!res.ok) {
      throw new Error('Failed to fetch tenant');
    }

    const body = await Promise.resolve(await res.json());
    console.log('body', body);
    const tenant = new Tenant(body);
    console.log('tenant', tenant);
    console.log('res', res);
    return tenant;
  }

  /**
   * Fetch invitation
   *
   * @param {INewUser} data
   * @return {*}  {Promise<any>}
   * @memberof AgentService
   */
  async fetchInvitation(data: INewUser): Promise<any> {
    const res = await fetch('/api/admin/register', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      },
      body: JSON.stringify({
        email: data.email,
        name: data.name
      } as any)
    });

    if (!res.ok) {
      throw new Error('Failed to fetch invitation');
    }

    const body = await Promise.resolve(await res.json());

    if (body.error) {
      throw new Error(body.error);
    }

    return body;
  }

  /**
   * Fetch reservations
   *
   * @return {*}  {Promise<any>}
   * @memberof AgentService
   */
  async fetchReservations(): Promise<any> {
    const res = await fetch('/api/admin/reservations', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    });

    if (!res.ok) {
      throw new Error('Failed to fetch reservations');
    }

    const body = await Promise.resolve(await res.json());
    console.log('body', body);

    if (body.error) {
      throw new Error(body.error);
    }
    console.log('body', body);

    return body;
  }

  /**
   * Fetch users
   *
   * @return {*}  {Promise<IUser[]>}
   * @memberof AgentService
   */
  async fetchUsers(): Promise<IUser[]> {
    const res = await fetch('/api/admin/users', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    });

    if (!res.ok) {
      throw new Error('Failed to fetch users');
    }

    const body = await Promise.resolve(await res.json());
    console.log('body', body);

    if (body.error) {
      throw new Error(body.error);
    }

    return body;
  }

  /**
   * Fetch reservation
   *
   * @param {string} reservationId
   * @return {*}  {Promise<IReservation>}
   * @memberof AgentService
   */
  async fetchReservation(email: string, reservationId: string): Promise<IReservation> {
    const res = await fetch(`/api/user/reservation`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ email: email, reservationId: reservationId }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    });

    if (!res.ok) {
      throw new Error('Failed to fetch reservation');
    }

    const body = await Promise.resolve(await res.json());
    console.log('body', body);

    if (body.error) {
      throw new Error(body.error);
    }

    return body;
  }

  /**
   * Create wallet
   *
   * @return {*}  {Promise<any>}
   * @memberof AgentService
   */
  async createWallet(): Promise<any> {
    const res = await fetch('/api/user/onboard', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    });

    if (!res.ok) {
      throw new Error('Failed to create wallet');
    }

    const body = await Promise.resolve(await res.json());
    console.log('body', body);

    if (body.error) {
      throw new Error(body.error);
    }

    return body;
  }

  /**
   * Send credential
   *
   * @param {string} email
   * @param {string} reservationId
   * @param {string} walletId
   * @return {*}  {Promise<any>}
   * @memberof AgentService
   */
  async sendCredential(user: IUser): Promise<any> {
    const res = await fetch('/api/admin/send-credential', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      },
      body: JSON.stringify({
        email: user.email,
        tenant_id: user.tenant_id,
        wallet_id: user.wallet_id
      })
    });

    if (!res.ok) {
      throw new Error('Failed to send credential');
    }

    const body = await Promise.resolve(await res.json());
    console.log('body', body);

    if (body.error) {
      throw new Error(body.error);
    }

    return body;
  }

  /**
   * Send email
   *
   * @param {string} email
   * @return {*}  {Promise<any>}
   * @memberof AgentService
   */
  async sendEmail(email: string): Promise<any> {
    const res = await fetch('/api/admin/send-email', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      },
      body: JSON.stringify({
        email: email
      })
    });

    if (!res.ok) {
      throw new Error('Failed to send email');
    }

    const body = await Promise.resolve(await res.json());
    console.log('body', body);

    if (body.error) {
      throw new Error(body.error);
    }

    return body;
  }
}

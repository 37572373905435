import React, { useState } from 'react';
import { Modal, View, Text, FlatList, StyleSheet, Button } from 'react-native';
import Spinner from '../components/molecules/Spinner';
import BlankModal from '../modal/BlankModal';
import { IUser } from '../types/interfaces';
import { useTenantContext } from '../contexts';
import { useAgentService } from '../services';
import { TenantInvitationModal } from '../modal/InvitationModal';

const Tenants: React.FC = () => {
  const tenantContext = useTenantContext();
  const agentService = useAgentService();

  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleSendCredential = async (userData: IUser) => {
    setOpen(false);
    setLoading(true);
    await agentService.sendCredential(userData);
    setLoading(false);
  };

  const sendInvitation = (user: IUser) => {
    setUser(user);
    setOpen(true);
  };

  const renderItem = ({ item }: { item: IUser }) => (
    <View style={styles.card}>
      <>
        <Text style={styles.name}>
          <strong>{item.tenant_name}</strong>
        </Text>
        <Text style={styles.name}>{item.email}</Text>
        <Text style={styles.id}>{item.tenant_id}</Text>
        <Text style={styles.id}>{item.wallet_id}</Text>
      </>
      <>
        <Button title="Send Credential" onPress={() => sendInvitation(item)} />
      </>
    </View>
  );

  return (
    <View style={styles.container}>
      <Modal visible={loading} transparent={true}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.textTitle}>Sending Credential</Text>
            <Spinner styleOverride={{ borderColor: 'black' }} />
          </View>
        </View>
      </Modal>
      <BlankModal title={'Send Credential'} open={open} onClose={() => setOpen(false)}>
        <TenantInvitationModal user={user} handleSendCredential={handleSendCredential} />
      </BlankModal>
      <Text style={styles.header}>Tenant Users</Text>

      {!tenantContext.users ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Spinner />
        </View>
      ) : tenantContext.users?.length === 0 ? (
        <Text style={{ color: 'white', textAlign: 'center' }}>No Users found</Text>
      ) : (
        <FlatList
          data={tenantContext.users || []}
          renderItem={renderItem}
          keyExtractor={(item) => item.user_id}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 8,
    padding: 4,
    backgroundColor: 'lightgray'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#333'
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 20,
    color: 'white'
  },
  item: {
    padding: 10,
    marginHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc'
  },
  name: {
    fontSize: 16
  },
  date: {
    fontSize: 14,
    color: '#666'
  },
  id: {
    fontSize: 10,
    color: '#666'
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 6,
    width: 500,
    alignItems: 'center'
  },
  textTitle: {
    fontSize: 24,
    fontWeight: 'bold'
  }
});

export default Tenants;

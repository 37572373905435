import { useAuth } from 'react-oidc-context';
import AgentService from './agent';
import { useMemo } from 'react';

export const useAgentService = (): AgentService => {
  const auth = useAuth();
  const agent = new AgentService(auth?.user?.access_token || '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const agentService = useMemo(() => agent, []);

  return agentService;
};

import React, { useState } from 'react';
import { View, Text, FlatList, StyleSheet, Button } from 'react-native';
import Spinner from '../components/molecules/Spinner';
import BlankModal from '../modal/BlankModal';
import { useTenantContext } from '../contexts';
import { ReservationInvitationModal } from '../modal/InvitationModal';
export interface IReservation {
  user_id?: string;
  reservation_id: string;
  reservation_pwd: string;
  tenant_name: string;
  email: string;
}

const Reservations: React.FC = () => {
  const tenantContext = useTenantContext();

  const [open, setOpen] = useState(false);
  const [invitation, setInvitation] = useState<IReservation | undefined>(undefined);

  const reload = async () => {
    await tenantContext.fetchReservations();
    await tenantContext.fetchUsers();
  };

  const sendInvitation = (reservation: IReservation) => {
    setInvitation(reservation);
    setOpen(true);
  };

  const renderItem = ({ item }: { item: IReservation }) => (
    <View style={styles.card}>
      <>
        <Text style={styles.name}>
          <strong>{item.tenant_name}</strong>
        </Text>
        <Text style={styles.name}>{item.email}</Text>
        <Text style={styles.id}>{item.reservation_id}</Text>
      </>
      <>
        <Button title="Send Invitation" onPress={() => sendInvitation(item)} />
      </>
    </View>
  );

  return (
    <View style={styles.container}>
      <BlankModal
        title={'DEMO EMAIL'}
        open={open}
        onClose={async () => {
          await reload();
          setOpen(false);
        }}>
        <ReservationInvitationModal reservation={invitation} />
      </BlankModal>
      <Text style={styles.header}>Reservations</Text>

      {!tenantContext.reservations ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Spinner />
        </View>
      ) : tenantContext.reservations?.length === 0 ? (
        <Text style={{ color: 'white', textAlign: 'center' }}>No reservations found</Text>
      ) : (
        <FlatList
          data={tenantContext.reservations || []}
          renderItem={renderItem}
          keyExtractor={(item) => item.reservation_id}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    margin: 8,
    padding: 4,
    backgroundColor: '#444'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#333'
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 20,
    color: 'white'
  },
  item: {
    padding: 10,
    marginHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc'
  },
  name: {
    fontSize: 18,
    color: 'white'
  },
  date: {
    fontSize: 14,
    color: '#666'
  },
  id: {
    fontSize: 12,
    color: '#666'
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 6,
    width: 500,
    alignItems: 'center'
  },
  textTitle: {
    fontSize: 24,
    fontWeight: 'bold'
  }
});

export default Reservations;

import { Button, StyleSheet, Text, View } from 'react-native';
import { IUser } from '../types/interfaces';
import { IReservation } from '../features/Reservations';
import { useAgentService } from '../services';

export interface ITenantInvitationModal {
  user: IUser | undefined;
  handleSendCredential: (user: IUser) => void;
}

export const TenantInvitationModal: React.FC<ITenantInvitationModal> = ({
  user,
  handleSendCredential
}) => {
  if (!user) {
    return null;
  }

  return (
    <View style={styles.modalContainer}>
      <View style={styles.modalContent}>
        <Text>
          <strong>User:</strong> {user.tenant_name}
        </Text>
        <Text>
          <strong>Email:</strong> {user.email}
        </Text>
        <Text>
          <strong>Tenant Id:</strong> {user.tenant_id || 'N/A'}
        </Text>
        <Text>
          <strong>Wallet Id:</strong> {user.wallet_id || 'N/A'}
        </Text>
        <View style={{ paddingVertical: 16 }}>
          <Button title="Create Credential" onPress={() => handleSendCredential(user)} />
        </View>
      </View>
    </View>
  );
};

export const ReservationInvitationModal: React.FC<{ reservation: IReservation | undefined }> = ({
  reservation
}) => {
  const agentService = useAgentService();

  if (!reservation) {
    return null;
  }

  const handleSendEmail = async () => {
    await agentService.sendEmail(reservation.email);
  };

  return (
    <View style={styles.modalContainer}>
      <View style={styles.modalContent}>
        <Text>
          <strong>Invitation sent to:</strong> {reservation.email}
        </Text>
        <Text>
          <strong>Reservation ID: </strong>
          {reservation.reservation_id}
        </Text>
        <Text>
          <strong>Reservation Password: </strong>
          {reservation.reservation_pwd}
        </Text>

        <Button title="Send Email" onPress={handleSendEmail} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 6,
    width: 500,
    alignItems: 'center'
  }
});

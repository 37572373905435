import React from 'react';
import { View, Text, StyleSheet, Button } from 'react-native';

const FallbackPage: React.FC = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Access Denied</Text>
      <Text style={styles.message}>You are not authorized to view this page.</Text>
      <Button
        title="Looking for Wallet? Click here"
        onPress={() => window.open('http://localhost:5173/', '_blank')} //to open new page
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    color: 'white'
  },
  message: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 32,
    color: 'white'
  }
});

export default FallbackPage;

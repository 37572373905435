import React, { useContext, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import Navigator from '../features/Navigator';
import Reservations from '../features/Reservations';
import Tenants from '../features/Tenants';
import { TenantContext } from '../contexts';
import { FullScreenSpinner } from '../components/molecules/Spinner';

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 8
  },
  navigatorContainer: {
    flex: 1,
    paddingRight: 8
  },
  consoleContainer: {
    flex: 3,
    paddingLeft: 8
  }
});

const TenantPage: React.FC = () => {
  const tenantContext = useContext(TenantContext);

  useEffect(() => {
    const fetchData = async () => {
      await tenantContext.fetchTenant();
      await tenantContext.fetchReservations();
      await tenantContext.fetchUsers();
    };

    if (!tenantContext.tenant || !tenantContext.reservations || !tenantContext.users) {
      fetchData();
    }
  }, [tenantContext]);

  if (
    !tenantContext ||
    !tenantContext.tenant ||
    !tenantContext.reservations ||
    !tenantContext.users
  ) {
    return <FullScreenSpinner />;
  }

  return (
    <View style={styles.container}>
      <View style={styles.navigatorContainer}>
        <Navigator />
      </View>
      <View style={styles.navigatorContainer}>
        <Reservations />
      </View>
      <View style={styles.navigatorContainer}>
        <Tenants />
      </View>
    </View>
  );
};

export default TenantPage;

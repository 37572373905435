import React, { createContext, ReactNode, useContext } from 'react';
import { AuthContextProps } from 'react-oidc-context';
import Tenant from '../types/tenant';
import { IReservation } from '../features/Reservations';
import { IUser } from '../types/interfaces';

export interface IAuthState {
  /**
   * The logged in user's Keycloak information.
   *
   * @type {AuthContextProps}
   * @memberof IAuthState
   */
  auth: AuthContextProps;
}

export const AuthStateContext = React.createContext<IAuthState | undefined>(undefined);

export interface IConfig {
  //Keycloak
  REACT_APP_KEYCLOAK_URL: string;
  REACT_APP_KEYCLOAK_REALM: string;
  REACT_APP_KEYCLOAK_CLIENT_ID: string;
}

export const ConfigContext = React.createContext<IConfig | undefined>(undefined);

export interface ITenantContext {
  tenant: Tenant | undefined;
  reservations: IReservation[] | undefined;
  users: IUser[] | undefined;
  // fetch user reservation
  fetchReservation: (email: string, reservationId: string) => Promise<IReservation | undefined>;
  // fetch admin lists
  fetchReservations: () => Promise<IReservation[] | undefined>;
  fetchTenant: () => Promise<Tenant | undefined>;
  fetchUsers: () => Promise<IUser[] | undefined>;
}

export const TenantContext = createContext<ITenantContext>({
  tenant: undefined,
  reservations: [],
  users: [],
  fetchTenant: async () => undefined,
  fetchUsers: async () => undefined,
  fetchReservation: async () => undefined,
  fetchReservations: async () => undefined
});

export type TenantContextProviderProps = {
  children: ReactNode;
};

/**
 * Hook to access the TenantContext
 *
 * @return {*}  {ITenant}
 */
export const useTenantContext = (): ITenantContext => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
};

/**
 * Hook to access the ConfigContext
 *
 * @return {*}  {IConfig}
 */
export const useConfigContext = (): IConfig => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

/**
 * Hook to access the AuthStateContext
 *
 * @return {*}  {IAuthState}
 */
export const useAuthStateContext = (): IAuthState => {
  const context = useContext(AuthStateContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthStateContextProvider');
  }
  return context;
};
